body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.continue-on-stripe {
    text-align: center;
    padding: 50px;
}

.continue-on-stripe > div {
    padding: 50px;
    background-color: #FAFAFA;
}

.continue-on-stripe h3 {
    line-height: 100px;
    margin-bottom: 50px;
}

.continue-on-stripe img {
    vertical-align: middle;
}