body.invoice {
    margin: auto;
    max-width: 23cm;
}

#invoice {
    font-family: Arial, sans-serif;
}

#first_row {
    width: 100%;
    height: 80px;
    margin: 0;
}

#first_row h1 {
    float: left;
    padding: 10px 20px;
    margin: 0;
}

#first_row #datetime {
    margin: 0;
    float: right;
    padding: 15px 10px;
    height: 50px;
    text-align: right;
}

#company {
    font-size: 10px;
    margin-left: 20px;
    font-weight: lighter;
}

#header {
    border-bottom: 1px solid black;
    margin-bottom: 50px;

}

#header_2 {
    font-size: 15px;
    padding-top: 15px;
    margin-left: 20px;
}

#center {
    width: 17cm;
    margin: 3cm auto;
}

#total {
    margin: 20px auto;
    width: 16cm;
    padding-right: 20px;
    font-weight: bold;
    text-align: right;
}

#table {
    border: 3px solid black;
    margin: 0 auto;
    border-spacing: 0;
    border-collapse: collapse;
}

#table td, #table th {
    text-transform: uppercase;
    border: 1px solid black;
    padding: 0 10px;
    margin: 0;
}

#table .number-cell {
    text-align: right;
}

#header_bar {
    background-color: #D13F33;
    height: 20px;
}

#header {
    background-color: #EDEDED;
}

#invoice_title {
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
}

#date, #invoice_id {
    border-bottom: 1px solid black;
    text-align: center;
    font-weight: bold;
    padding-bottom: 5px;
    margin-top: 30px;
}

#bill_to_header {
    font-weight: bold;
    font-size: 20px;
    border-bottom: 1px solid black;
}

#bill_to {
    margin-right: 10px;
    margin-left: auto;
    max-width: 5cm;
    margin-bottom: 30px;
}

#table th {
    background: #D13F33;
    color: white;
}


#amount {
    margin-left: 10px;
}

#bill_to {
    text-align: right;
}

#bill_to_header {
    text-align: left;
}

#page {
    border: 1px solid black;
    overflow-y: scroll;
    max-height: 29.7cm;
    height: 29.7cm;
    margin-top: 50px;
}

#container {
    position: relative;
    padding: 50px;
}

#actions {
    position: fixed;
    background: #FAFAFA;
    width: 100vw;
    left: 0;
    bottom: 0;
    text-align: center;
}

#actions button {
    margin: 5px auto;
}